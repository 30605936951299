import * as L from 'leaflet'
import 'leaflet.markercluster'
import { ofetch } from 'ofetch'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import './MarkerCluster.Custom.css'

type Pharmacy = { lat: string; lng: string; html: string | null | undefined }

async function initializeMap(mapElement: HTMLElement) {
	const apiKey = mapElement.dataset.apiKey
	if (!apiKey) return

	// Set up the map with controls
	const mapConfig = mapElement.querySelector('.js-map-config')
	const { lat, lng, zoom } = {
		lng: mapConfig?.querySelector('.js-map-config__lng')?.textContent ?? '0',
		lat: mapConfig?.querySelector('.js-map-config__lat')?.textContent ?? '0',
		zoom: mapConfig?.querySelector('.js-map-config__zoom')?.textContent ?? '17'
	}
	const map = new L.Map(mapElement)
	map.setView([+lat, +lng], +zoom)

	// Fetch configuration for the map
	const { attribution, logo, tiles } = await ofetch<{ attribution: string; logo: string; tiles: string[] }>(
		`https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${apiKey}`
	)

	// Add tile layer using the loaded configuration with attribution text
	map.addLayer(new L.TileLayer(tiles[0], { attribution }))

	// Add attribution logo
	const attributionLogo = new L.Control.Attribution({
		position: 'bottomleft',
		prefix: false
	})

	attributionLogo.onAdd = () => {
		const container = L.DomUtil.create('div')
		const link = L.DomUtil.create('a', '', container)
		link.setAttribute('href', 'http://mapy.cz/')
		link.setAttribute('target', '_blank')
		link.innerHTML = `<img src="${logo}" alt="" />`
		L.DomEvent.disableClickPropagation(link)

		return container
	}
	attributionLogo.addTo(map)

	// Add pharmacies to the map
	const pharmacies: Pharmacy[] = []
	mapElement.querySelectorAll('.js-map-data-item').forEach((item) => {
		const { lat, lng, html } = {
			lng: item.querySelector('.js-map-data-item__lng')?.textContent,
			lat: item.querySelector('.js-map-data-item__lat')?.textContent,
			html: item.querySelector('.js-map-data-item__html')?.textContent
		}

		if (!lat || !lng) return
		pharmacies.push({ lat, lng, html })
	})

	const icon = new L.Icon({
		iconUrl: '/img/logo-benu-kriz.svg',
		iconSize: [30, 30],
		iconAnchor: [15, 30],
		popupAnchor: [0, -30]
	})
	const markersList: L.Marker[] = []
	const markers = new L.MarkerClusterGroup({
		showCoverageOnHover: false
	})
	pharmacies.forEach((pharmacy) => {
		const marker = new L.Marker([+pharmacy.lat, +pharmacy.lng], {
			icon,
			interactive: !!pharmacy.html
		})
		pharmacy.html && marker.bindPopup(pharmacy.html)
		markersList.push(marker)
		markers.addLayer(marker)
	})
	map.addLayer(markers)
	map.fitBounds(markers.getBounds())
}

declare global {
	interface JQueryStatic {
		nette: any
	}
}

document.addEventListener('DOMContentLoaded', () => {
	$.nette.ext(
		'seznamMapInitializer',
		{
			init: function () {
				const snippetsExt = this.ext('snippets', true)

				snippetsExt.after(($el: JQuery<HTMLElement>) => {
					this.init.call(this, $el)
				})

				this.init($(document))
			}
		},
		{
			init: function ($el: JQuery<HTMLElement>) {
				$el.find<HTMLElement>('.js-mapCanvas').each((_, element) => {
					initializeMap(element)
				})
			}
		}
	)
})
